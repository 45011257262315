.rteditor-wrapper {
    border: 1px solid #F1F1F1;
}

.rteditor-wrapper .rteditor-toolbar {
    border: none;
    margin: 0;
    background: #d4eae4;
}

.rteditor-wrapper .rteditor-toolbar .rdw-option-active, .block-editor-toolbar .rdw-option-active:hover {
    box-shadow: -1px -1px 1px 2px #005a70;
}

.rteditor-wrapper .rdw-option-wrapper:hover {
    box-shadow: 1px 1px 0px #005a70;
}

.rteditor-wrapper .rdw-option-wrapper {
    background: transparent;
    border: none;
}

.rteditor-wrapper .rteditor {
    padding: 5px 10px;
    height: calc(50vh - 40px);
    line-height: 1.5;
}

.reduce-editor .rteditor-wrapper .rteditor {
    height: 40vh;
}

.rteditor-wrapper .rdw-link-modal-target-option {
    display: none;
}
