body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-sider-collapsed .logo {
  font-size: 0.8rem;
  text-align: left;
  margin: 16px 8px;
}

.login-form-button {
  width: 100%;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mr-2 {
  margin-right: 1.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-2 {
  margin-top: 1.5rem;
}

.divider {
  margin: 1.5rem 0 1rem 0;
  border-top: 1px solid #aaa;
  width: 100%;
}


.ant-form-item-label {
  text-align: left !important;
}

.ant-form-item-label > label::after {
  content: '' !important;
}

/*
* Block Items
*/

.block-item {
  border-radius: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #fff;
  margin-bottom: 1rem;
}

.block-item.twyllable {
  background: #eee;
  border: 1px solid #ddd;
}

.block-item .block-item__content .paragraph-content > * {
  margin: 0;
}

.block-item .block-action {
  visibility: hidden;
  opacity: 0;
  transition: 300ms ease all;
}

.block-item:hover .block-action {
  visibility: visible;
  opacity: 1;
}

.block-item .merge-up, .block-item .merge-down {
  position: relative;
  left: calc(50% - 25px);
}

.block-item .toggle-twyllable {
  position: absolute;
  display: block;
  z-index: 2;
  left: 230px;
}

.block-item .merge-up {
  top: -.4rem;
}

.block-item .merge-down {
  bottom: -.4rem;
}

.block-items-editor > .block-item:first-child .merge-up,
.block-items-editor > .block-item:last-child .merge-down {
  visibility: hidden;
}

.block-items-editor {
  padding-left: 2rem;
}

.react-contexify {
  z-index: 2;
}

blockquote {
  padding-left: .5rem;
  border-left: 4px solid #d8d8d8;
}
